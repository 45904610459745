export const region = 'ap-northeast-1';
export const stage = 'develop';
export const bkpPath = 'https://edge.bitkey.tokyo/v1';
export const ifBaseApiPath = 'https://develop.api.og.bitlock.xyz/link/api/v1';
export const bitlockApiPath =
  'https://us-central1-bitlock-staging.cloudfunctions.net/ext-v1';
export const bitlockVoApiPath =
  'https://us-central1-bitlock-staging.cloudfunctions.net/ext-vo';
export const bitlockVoApiKey = `xkoejfkwjkdhsfjkh7348979285-2nklnfklnfklfl.ekkjnfgknjkn.mvffl`;
export const bitlockVoVerifyIdentityApiKey = `dhfhsdhfuodsghfhflh782942oyvuhnvakkqkdkhvbfrwrywv4756295viu24y587`;
